import React from 'react';
import styles from './member-qoutes.module.scss';
import 'styles/_fontsFamily.module.scss';
import { API } from '@/constants/global-config';

interface IProps {}

const MemberQoutes: React.FC<IProps> = () => {
  return (
    <div className={styles.mem_qoute_container}>
      <div className={styles.inner_mem_qoute_container}>
        <div className={styles.mem_qoute_box1}>
          <h5 className={styles.member_saying_text}> WHAT MEMBERS ARE SAYING </h5>
          <div>
            <img className={styles.review_img} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/Reviews.svg`} />
          </div>
          <div>
            <p className={styles.box1_commit_text}>
              <img className={styles.qoute_img} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/Quote.svg`} />
            </p>
            <p className={styles.box1_name_text}> — Gina T. </p>
          </div>
        </div>
        <div className={styles.mem_qoute_box2}>
          <div className={styles.inner_div}>
            <div className={`${styles.innerbox} ${styles.bg_dark_gry}`}>
              <p className={styles.box2_commit_text}>“The customer service is great & shipping is so fast!”</p>
              <p className={styles.box2_name_text}>— Natalie R.</p>
            </div>
            <div className={`${styles.innerbox} ${styles.bg_light_gry}`}>
              <p className={styles.box2_commit_text}>
                “It has saved me SO <br className="d-block d-mdm-none" /> much time + money!”
              </p>
              <p className={styles.box2_name_text}>— Heather K.</p>
            </div>
          </div>
          <div className={styles.inner_div}>
            <div className={`${styles.innerbox} ${styles.bg_light_gry}`}>
              <p className={styles.box2_commit_text}>
                “The clothes are kept <br className="d-block d-mdm-none" /> in pristine condition.”
              </p>
              <p className={styles.box2_name_text}>— Chloe A.</p>
            </div>
            <div className={`${styles.innerbox} ${styles.bg_dark_gry}`}>
              <p className={styles.box2_commit_text}>“Returning is so simple, I get a new order every week! ”</p>
              <p className={styles.box2_name_text}>— Corrine R.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberQoutes;
