import React, { useState } from 'react';
import styles from './brands.module.scss';
import { useResponsive } from '@/libraries/responsive';
import { API } from '@/constants/global-config';
import 'styles/_fontsFamily.module.scss';
import Link from 'next/link';
import { useAppSelector } from '@/hooks/redux-hooks';

interface IProps {
  isSmallScreen: boolean;
 }

const Brands: React.FC<IProps> = ({isSmallScreen}) => {
  const { isTablet } = useResponsive();
  const [profile, setProfile] = useState('a');
  const customer = useAppSelector(state => state.customer.customer)
  const customer_subscription = customer !== 'not_login' ? customer.has_subscription : false
  const lastRentalOrderId = (customer != 'not_login' && customer.lastRentalOrder && customer.lastRentalOrder.orderid) ? customer.lastRentalOrder.orderid : -1 ;
  return (
    // <div className={styles.container} style={{ marginTop: !customer_subscription ? '50px' : "" }}>
    <div className={styles.container}>
      {!customer_subscription ? <h5 className={styles.title}> All Your Favorite Brands </h5> : <h5 className={styles.title}> Rent From Your Favorite Brands </h5>}

      {isTablet ? (
        <div className={styles.mobile_brands_container}>
          <div className={styles.brand_logo_div}>
            <Link className={styles.logo} href="/for-love-and-lemons">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/For_Love_&_Lemons_Logo.png?profile==${profile}`} />
            </Link>
            <Link className={styles.logo} href="/good-american">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Good_american_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/free-people">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/freepeople_logo.png?profile=${profile}`} />
            </Link>
          </div>
          <div className={styles.brand_logo_div}>
            <Link className={styles.logo} href="/amanda-uprichard">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/amanda_uprichard_logo.png?profile=${profile}`} />
            </Link>
            <Link className={`${styles.logo} text-center`} href="/astr">
              <img style={{ maxWidth:"70%" }} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/astr-logo-strong.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/sabina-musayev">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Sabina_logo.png?profile=${profile}`} />
            </Link>
          </div>
          <div className={styles.brand_logo_div}>
            <Link className={styles.logo} href="/show-me-your-mumu">
              <img className={`${styles.mumu_logo}`} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Show_me_your_mumu_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/beach-riot">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/BeachRiot_logo_mobile.webp?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/varley">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Varley_logo.png?profile=${profile}`} id={isTablet ? 'plans' : ''} />
            </Link>
            <Link className={styles.logo} href="/loveshackfancy">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/LoveShackFancy.png?profile=${profile}`} />
            </Link>
          </div>
          {customer == 'not_login' ? <Link href='/signup'><p className={styles.try_now}>try now</p></Link> : ''}
          {customer !== 'not_login' && !customer_subscription ? <Link href='/signup'><p className={styles.reactivate_btn}>REACTIVATE</p></Link> : ''}
        </div>
      ) : (
        <div className={styles.desktop_brands_container}>
          <div className={styles.brand_logo_div}>
            <Link className={styles.logo} href="/for-love-and-lemons">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/For_Love_&_Lemons_Logo.png?profile=${profile}`} />
            </Link>
            <Link className={`${styles.logo} text-center`} href="/show-me-your-mumu">
              <img className={`${styles.mumu_logo}`} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Show_me_your_mumu_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/free-people">
              <img className={styles.large_logo}  src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/freepeople_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/good-american">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Good_american_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/astr">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/astr-logo-strong.png?profile=${profile}`} />
            </Link>
          </div>
          <div className={styles.brand_logo_div} id={!isTablet ? 'plans' : ''}>
            <Link className={styles.logo} href="/varley">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Varley_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/loveshackfancy">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/LoveShackFancy.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/beach-riot">
              <img className={styles.large_logo} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/BeachRiot_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/amanda-uprichard">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/amanda_uprichard_logo.png?profile=${profile}`} />
            </Link>
            <Link className={styles.logo} href="/sabina-musayev">
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/Sabina_logo.png?profile=${profile}`} />
            </Link>
          </div>
          {lastRentalOrderId  == -1 ? <Link href='/signup'><p className={styles.try_now}>Try Now</p></Link> : ''}
          {lastRentalOrderId  > -1  ? <Link href='/signup'><p className={styles.reactivate_btn}>REACTIVATE</p></Link> : ''}
        </div>
      )}
    </div>
  );
};

export default Brands;
