import React, { useState, useEffect } from 'react';
import styles from './rent-occasion.module.scss';
import { useAppSelector } from '@/hooks/redux-hooks';
import { SystemSettings } from '@/constants/global-config';
import { API } from '@/constants/global-config';
import 'styles/_fontsFamily.module.scss';
import Link from 'next/link';
import { useResponsive } from '@/libraries/responsive';
import Gconfig from 'globalconfig';

interface IProps {
  isSmallScreen: boolean;
  from: string
 }

type rentOccasionsDataType = { text: string; link: string; image: string; for: string, altText: string };

const RentOccasion: React.FC<IProps> = ({isSmallScreen, from}) => {
  const systemSettingsData = useAppSelector((state) => state.systemSettings);
  const customerData = useAppSelector((state) => state.customer);
  const [rentOccasion, setRentOccasion] = useState<rentOccasionsDataType[]>([]);
  const [isSubscription, setIsSubscription] = useState(false);
  const { isTablet, isMobile } = useResponsive();

  useEffect(() => {
    if (customerData.customer != 'not_login' && customerData.customer.has_subscription) {
      if (systemSettingsData.systemSettings.length > 0) {
        const rentOccasions = systemSettingsData.systemSettings.find((item) => item.setting_id.toString() === SystemSettings.RENT_OCCASION);
        if (rentOccasions == undefined) return;
        const data: rentOccasionsDataType[] = JSON.parse(rentOccasions.setting_value);

        let filter_ocasion = data.filter((item) => {
          return item.for == 'sub';
        });
        // console.log('filter_ocasion_data', data);
        // console.log('filter_ocasion', filter_ocasion);
        setRentOccasion(filter_ocasion);
      }
      setIsSubscription(true);
    } else {
      if (systemSettingsData.systemSettings.length > 0) {
        const rentOccasions = systemSettingsData.systemSettings.find((item) => item.setting_id.toString() === SystemSettings.RENT_OCCASION);
        if (rentOccasions == undefined) return;
        const data: rentOccasionsDataType[] = JSON.parse(rentOccasions.setting_value);
        let filter_ocasion = data.filter((item) => {
          return item.for == 'non_sub';
        });
        setRentOccasion(filter_ocasion);
      }
      setIsSubscription(false);
    }
  }, [customerData.customer]);

  return (
    <div className={`${isSmallScreen ? `${styles.container} ${styles.small_device}` : `${styles.container}`}  ${isSubscription == true || from == "releaseHold" ? styles.custom_mt_container : styles.custom_mt_container_nonsub} ${from == "releaseHold" ? styles.releaseItem_cont : ''}`}>
      {/* {isSubscription == false ? <h4 className={styles.rent_heading}> Rent for Every Occasion </h4> : ''} */}
      {(isSubscription == false && from != "releaseHold") ?
        <div className={styles.rent_heading_div} >
          <img src={`${Gconfig.fashionpassImageStorage}assets/img/rentfor_nonmember_homepage.gif?profile=a`} />
        </div>
      : ''}
      <div className={styles.card_conatainer}>
        {rentOccasion.length > 0 &&
          rentOccasion.map((val, ind) => {
            return (
              <div key={ind} className={styles.card}>
                <Link href={val.link}>
                  <img src={val.image} alt={val?.altText} />
                  <p className={`${styles.card_text}`}>{val.text}</p>
                </Link>
              </div>
            );
          })}
      </div>
      {/* {isMobile ? <div id="plans" className={styles.mobile_plansec}></div> : <div id="plans" className={styles.desktop_plansec}></div>} */}
    </div>
  );
};
export default RentOccasion;
