import { API } from '@/constants/global-config';
import { useAppSelector } from '@/hooks/redux-hooks';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styles from './hero-image.module.scss';
import { SystemSettings } from '@/constants/global-config';
import 'styles/_fontsFamily.module.scss';
import jsCookie from 'js-cookie';
import Gconfig from 'globalconfig';
import WaveLoader from 'components/waveLoader';

interface IProps { }

type settingObjValues = {
  userType: string;
  url: string;
  extension: string;
  link: string;
  thumbnail: string;
  altText: string;
};

const HeroImage: React.FC<IProps> = () => {
  const systemSettingsData = useAppSelector((state) => state.systemSettings);
  const customerData = useAppSelector((state) => state.customer);
  const [desktopData, setDesktopData] = useState<settingObjValues | null>(null);
  const [mobileData, setMobileData] = useState<settingObjValues | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaderHide, setIsLoaderHide] = useState(true);
  // const [assignedHome, setAssignedHome] = useState<number>(1);
  const [isPause, setIsPause] = useState(false);
  const [tabletMode, setTabletMode] = useState(false);


  // useEffect(() => {
  //   let abCookieValue = jsCookie.get('customerHomeAB');
  //   if (abCookieValue != undefined) {
  //     if (abCookieValue.indexOf('B') > -1) setAssignedHome(2);
  //   }
  // }, []);

  useEffect(() => {
    if (customerData.customer != 'not_login' && customerData.customer.has_subscription === false && customerData.customer.tag_list?.indexOf('Paused') > -1) {
      setIsPause(true)
    }
  }, [customerData.customer])

  useEffect(() => {
    const handleResize = () => {
      setTabletMode(window.innerWidth < 768)
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {


    if (systemSettingsData.systemSettings.length > 0) {
      setIsLoading(true);
      systemSettingsData.systemSettings.filter((val) => {
        if (val.setting_id.toString() === SystemSettings.HOMEPAGE_IMAGE_SETTING) {
          let value: settingObjValues[] = JSON.parse(val.setting_value);
          if (value) {
            setIsLoading(false);
            value.map((val, index) => {
              if (val.userType === 'nonsub-desktop') setDesktopData(val);
              if (val.userType === 'nonsub-mobile') setMobileData(val);
            });
          }
        }
      });
    }

    // if (customerData.customer !== 'not_login') {
    //   let userTagstring = customerData.customer.tag_list.join().toLowerCase();
    //   let utmCampaignCode = jsCookie.get('utm_campaign_code');
    //   if (userTagstring.indexOf('paused') > -1 && utmCampaignCode) {
    //     if(utmCampaignCode.toLowerCase() == 'poolside') {
    //       set_desk_img(Gconfig.fashionpassImageStorage + "assets/img/20off_desktop_pp.jpg")
    //       set_mob_img(Gconfig.fashionpassImageStorage + "assets/img/20off_mobile_pp.jpg")
    //     }
    //     else if(utmCampaignCode.toLowerCase() == 'starspangled') {
    //       set_desk_img(Gconfig.fashionpassImageStorage + "assets/img/30off_desktop_pp.jpg")
    //       set_mob_img(Gconfig.fashionpassImageStorage + "assets/img/30off_mobile_pp.jpg")
    //     }
    //     else if(utmCampaignCode.toLowerCase() == 'sparkle') {
    //       set_desk_img(Gconfig.fashionpassImageStorage + "assets/img/40off_desktop_pp.jpg")
    //       set_mob_img(Gconfig.fashionpassImageStorage + "assets/img/40off_mobile_pp.jpg")
    //     }
    //     else if(utmCampaignCode == 'star744X1') {
    //       set_desk_img(Gconfig.fashionpassImageStorage + "assets/img/freemonth_desktop_pp.jpg")
    //       set_mob_img(Gconfig.fashionpassImageStorage + "assets/img/freemonth_mobile_pp.jpg")
    //     }
    //     else {
    //       set_desk_img(Gconfig.fashionpassImageStorage + "assets/img/40off_desktop_ns.jpg")
    //       set_mob_img(Gconfig.fashionpassImageStorage + "assets/img/40off_mobile_ns.jpg")
    //     }
    //   }
    //   else {
    //     set_desk_img(Gconfig.fashionpassImageStorage + "assets/img/40off_desktop_ns.jpg")
    //     set_mob_img(Gconfig.fashionpassImageStorage + "assets/img/40off_mobile_ns.jpg")
    //   }
    // }
    // else {
    //   set_desk_img(Gconfig.fashionpassImageStorage + "assets/img/40off_desktop_ns.jpg")
    //   set_mob_img(Gconfig.fashionpassImageStorage + "assets/img/40off_mobile_ns.jpg")
    // }

  }, []);

  return (
    <section id="home-video" className={styles.container}>
      {/* {isPause ?
        <>
          {!isTablet &&
            <Link href='/signup' className={styles.imageLink}>
              <img
                className={styles.img}
                src={Gconfig.fashionpassImageStorage + 'assets/img/denimDaze_HP_1.2_01.jpg?profile=a'}
                onLoad={() => {
                  setIsLoaderHide(false);
                }}
                style={isLoaderHide ? { display: 'none' } : { display: 'block' }}
                alt="NonSubscribed User Home Image - Desktop"
              />
            </Link>
          }

          {isTablet &&
            <Link href='/signup' className={styles.imageLink}>
              <img
                className={styles.img}
                src={Gconfig.fashionpassImageStorage + 'assets/img/denimDaze_HP_1.2_03.jpg?profile=b'}
                onLoad={() => {
                  setIsLoaderHide(false);
                }}
                style={isLoaderHide ? { display: 'none' } : { display: 'block' }}
                alt="NonSubscribed User Home Image - Mobile"
              />
            </Link>
          }
          {!isLoading && isLoaderHide ? (
            <div className={styles.home_hero_loader}>
              <WaveLoader size="large" />
            </div>
          ) : (
            ''
          )}
        </>
        : */}
      {isLoading ? (
        <div className={styles.home_hero_loader}>
          {/* <img src="/assets/img/loader-main-pink.gif" alt="loader2" /> */}
          <WaveLoader size="large" />
        </div>
      ) : (
        <>
          {desktopData !== null && (!tabletMode ? desktopData.extension == '.mp4' || desktopData.extension == '.mov' : (mobileData !== null && mobileData.extension == '.mp4') || (mobileData !== null && mobileData.extension == '.mov')) ? (
            <div className={styles.video_banner}>
              {tabletMode ? (
                <Link href={mobileData !== null ? mobileData.link : ''}>
                  <video
                    id="myVideo"
                    className="blurFilter"
                    onLoadedData={() => {
                      setIsLoaderHide(false);
                    }}
                    poster={mobileData.thumbnail + '?profile=b'}
                    playsInline
                    preload="metadata"
                    autoPlay
                    loop
                    muted
                  >
                    <source src={mobileData !== null ? mobileData.url : ''} type="video/mp4" />
                  </video>
                </Link>
              ) : (
                <Link href={desktopData !== null ? desktopData.link : ''}>
                  <video
                    id="myVideo"
                    className="blurFilter"
                    onLoadedData={() => {
                      setIsLoaderHide(false);
                    }}
                    poster={desktopData.thumbnail + '?profile=a'}
                    playsInline
                    preload="metadata"
                    autoPlay
                    loop
                    muted
                  >
                    <source src={desktopData !== null ? desktopData.url : ''} type="video/mp4" />
                  </video>
                </Link>
              )}
            </div>
          ) : (
            <>
              {!tabletMode && desktopData !== null && (
                <div className='position-relative'>
                  <Link href={desktopData !== null ? desktopData.link : ''} className={styles.imageLink}>
                    <img
                      className={styles.img}
                      onLoad={() => {
                        setIsLoaderHide(false);
                      }}
                      style={isLoaderHide ? { display: 'none' } : { display: 'block' }}
                      src={desktopData !== null ? desktopData.url + '?profile=a' : ''}
                      alt={desktopData?.altText}
                    />
                    {!isLoaderHide && <p className={styles.clothing_btn}>Browse Clothing</p>}
                  </Link>
                </div>
              )}
              {tabletMode && mobileData !== null && (
                <div className='position-relative'>
                  <Link href={mobileData !== null ? mobileData.link : ''} className={styles.imageLink}>
                    <img
                      className={styles.img}
                      onLoad={() => {
                        setIsLoaderHide(false);
                      }}
                      style={isLoaderHide ? { display: 'none' } : { display: 'block' }}
                      src={mobileData !== null ? mobileData.url + '?profile=b' : ''}
                      alt={mobileData?.altText}
                    />
                    {!isLoaderHide ? <p className={styles.clothing_btn}>Browse Clothing</p> : ''}
                  </Link>
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* {
        assignedHome == 1 ? 
          <>
           {!isTablet ? (
             <Link href={'/summer-sale'} className={styles.imageLink}>
               <img className={styles.img} onLoad={()=>{setIsLoaderHide(false)}} src={Gconfig.fashionpassImageStorage +"assets/img/homepage_sale_desktop_nonsub.gif?profile=a"} alt="Subscribed User Home Image - Desktop" />
             </Link>
           ):(
             <Link href={'/summer-sale'} className={styles.imageLink}>
               <img className={styles.img} onLoad={()=>{setIsLoaderHide(false)}} src={Gconfig.fashionpassImageStorage +"assets/img/homepage_sale_mobile_nonsub.gif?profile=b"} alt="Subscribed User Home Image - Mobile" />
             </Link>
           )}
          </> 
          // :
            // (isTablet ? (
            //   <Link href={'/clothing'} className={styles.imageLink}>
            //     <img className={styles.img} onLoad={()=>{setIsLoaderHide(false)}} src={Gconfig.fashionpassImageStorage +"assets/img/Mobile_nonmember_video_ab_test_final_n.gif?profile=b"} alt="Subscribed User Home Image - Desktop" />
            //   </Link>
            // ) : (
            //   <div className={styles.video_banner}>
            //     <Link href={'/clothing'}>
            //       <video id="myVideo" className="blurFilter" onLoadedData={()=>{setIsLoaderHide(false)}} poster={Gconfig.fashionpassImageStorage +"assets/img/desktop_nonmember_abtest_previewimage_n.jpg?profile=a"} playsInline preload="metadata" autoPlay loop muted>
            //         <source src={Gconfig.fashionpassImageStorage +"assets/img/Desktop_nonmember_video_ab_test_final_n.mp4"} type="video/mp4" />
            //       </video>
            //     </Link>
            //   </div> 
            // ))
        
      } */}

      {/* {
        isLoaderHide ?
        <div className={styles.home_hero_loader}>
          <img src="/assets/img/loader-main-pink.gif" alt="loader2" />
        </div> : ''
      } */}

      {isPause == false && !isLoading && isLoaderHide ? (
        <div className={styles.home_hero_loader}>
          {/* <img src="/assets/img/loader-main-pink.gif" alt="loader2" /> */}
          <WaveLoader size="large" />
        </div>
      ) : (
        ''
      )}
    </section>
  );
};

export default HeroImage;
