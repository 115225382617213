import React from 'react';
// import ReactDOM from 'react-dom';
import { Component } from 'react';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(()=> import('react-responsive'), { ssr: false });
import Gconfig from 'globalconfig';
import { connect } from 'react-redux';
import Link from 'next/link';
import { SystemSettings } from '@/constants/global-config';
import WaveLoader from 'components/waveLoader';
import jsCookie from 'js-cookie';

class IndexHeaderImage extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading:false,
            desktopData: {},
            mobileData: {},
            isLoaderHide: true,
            isPause : false
        }
    }

    componentDidMount(){
      

        let systemSettings = this.props.systemSettings
        if(systemSettings && systemSettings.systemSettings && systemSettings.systemSettings.length > 0){
            this.setState({isLoading: true})
            systemSettings.systemSettings.filter(val => {
                if(val.setting_id == SystemSettings.HOMEPAGE_IMAGE_SETTING){
                    let value = JSON.parse(val.setting_value)
                    if(value && value.length > 0){
                        this.setState({isLoading: false})
                        value.map((val,ind)=>{
                            if(val.userType == 'sub-desktop')
                                this.setState({desktopData: val})
                            if(val.userType == 'sub-mobile')
                                this.setState({mobileData: val})
                        })
                    }
                }
            })
        }
    }

    render() {
        return (
            <section id="home-video" className="home-we-love-image blurFilter">
                {this.state.isPause ? 
                    <>
                        <MediaQuery maxWidth={767}  >
                            <Link  href='/signup' > <img src={Gconfig.fashionpassImageStorage+'assets/img/homepaused_subsale_mobile.jpg?profile=b'} /></Link>
                        </MediaQuery>
                        <MediaQuery minWidth={768} >
                            <Link  href='/signup' > <img className="desktop-header-img" src={Gconfig.fashionpassImageStorage+'assets/img/homepaused_subsale_desktop.jpg?profile=a' } /></Link> 
                        </MediaQuery>
                    </> :
                <>    
                {
                    this.state.isLoading ?
                    <div className="home-hero-loader">
                        {/* <img src='/assets/img/loader-main-pink.gif' alt="loader2" /> */}
                        <WaveLoader size="large"/>
                    </div>
                   : <>
                        { 
                            Object.keys(this.state.desktopData).length != 0 && (!this.state.isMobile ? (this.state.desktopData.extension == '.mp4' || this.state.desktopData.extension == '.mov') : (this.state.mobileData.extension == '.mp4' || this.state.mobileData.extension == '.mov'))  ?
                            <div className="video-banner">
                                
                                <span>{this.props.counter}</span>
                                    <MediaQuery maxWidth={767}>
                                        <Link href={Object.keys(this.state.mobileData).length != 0  ? this.state.mobileData.link : ''}>
                                            <video id="myVideo" className="blurFilter" playsInline onLoadedData={()=>{this.setState({isLoaderHide :false})}} poster={Object.keys(this.state.mobileData).length != 0  ? this.state.mobileData.thumbnail+'?profile=b' : ''} preload="metadata" autoPlay loop muted  >
                                                <source src={Object.keys(this.state.mobileData).length != 0  ? this.state.mobileData.url : ''} type="video/mp4" />
                                            </video>
                                        </Link>
                                    </MediaQuery>
                                    <MediaQuery minWidth={768}>
                                        <Link href={Object.keys(this.state.desktopData).length != 0  ? this.state.desktopData.link : ''}>
                                            <video id="myVideo" className="blurFilter" playsInline preload="metadata" onLoadedData={()=>{this.setState({isLoaderHide :false})}} autoPlay loop muted poster={Object.keys(this.state.desktopData).length != 0  ? this.state.desktopData.thumbnail+'?profile=a' : ''}  >
                                                <source src={Object.keys(this.state.desktopData).length != 0 ? this.state.desktopData.url : ''} type="video/mp4" />
                                            </video>
                                        </Link>
                                    </MediaQuery>
                            </div> :
                            <>
                                <MediaQuery maxWidth={767}  >
                                <Link  href={ Object.keys(this.state.mobileData).length != 0 ? this.state.mobileData.link : ''}><img onLoad={()=>{this.setState({isLoaderHide :false})}} src={ this.state.mobileData && this.state.mobileData.url+'?profile=b' } alt={this.state.mobileData?.altText} /></Link> </MediaQuery>
                                <MediaQuery minWidth={768} >
                                <Link  href={Object.keys(this.state.desktopData).length != 0 ? this.state.desktopData.link : ''}><img className="desktop-header-img" onLoad={()=>{this.setState({isLoaderHide :false})}} src={ this.state.desktopData && this.state.desktopData.url+'?profile=a' } alt={this.state.desktopData?.altText} /></Link> </MediaQuery>
                            </> 
                        }
                    </>
                }
                {
                    this.state.isPause == false && !this.state.isLoading && this.state.isLoaderHide ?
                    <div className="home-hero-loader">
                        {/* <img src="/assets/img/loader-main-pink.gif" alt="loader2" /> */}
                        <WaveLoader size="large" />
                    </div> : ''
                }
                </>}
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        systemSettings: state.systemSettings,
        customer: state.customer,
    }
}

export default connect(mapStateToProps, null)(IndexHeaderImage);