import React from 'react';
import styles from '../brands/brands.module.scss';
import { API } from '@/constants/global-config';
import 'styles/_fontsFamily.module.scss';

interface IProps {
  fromHIW: boolean;
}

const HowItsWork: React.FC<IProps> = ({ fromHIW }) => {
  return (
    <div className={`${styles.container_h} ${fromHIW == true ? styles.container_custm_mt_h : ''}`}>
      <h4 className={styles.hiw_title}> How it Works </h4>
      <div>
        <div className={styles.hiw_content}>
          <div className={styles.hiw_content_container}>
            <div className={styles.hiw_text_box_one}>
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/AnUnlimtedClothingRentalSubscription.svg`}  />
            </div>
            <div className={styles.hiw_text_box_two}>
              <div className={styles.hiw_text_box_two_inner}>
                <p className={styles.order_text}>
                  Get a new order as often as you want, <br /> for one flat price paid monthly.
                </p>
                <p className={styles.plan_text}>
                  Select a plan to determine how many items <br className="d-none d-md-block" /> you get per order. Upgrade, downgrade, or <br className="d-none d-md-block" /> cancel your plan anytime. No commitments.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.hiw_item_container}>
          <div className={styles.hiw_item}>
            <span className={styles.hiw_itm_img_box}>
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/hiw_choose_new.svg`}  />
            </span>
            <span className={styles.hiw_itm_txt_box}>
              <p className={styles.text_title}> CHOOSE YOUR PLAN </p>
              <p className={styles.text_para}> Your plan will determine how many items you get per order + get exclusive member perks! </p>
            </span>
          </div>
          <div className={styles.hiw_item}>
            <span className={styles.hiw_itm_img_box}>
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/hiw_select_new.svg`}  />
            </span>
            <span className={styles.hiw_itm_txt_box}>
              <p className={styles.text_title}> SELECT YOUR ITEMS </p>
              <p className={styles.text_para}> Add items to your cart and head to checkout! You can add up to 2 extra items beyond your selected plan for $19.95 each. </p>
            </span>
          </div>
          <div className={`${styles.hiw_item} mb-3`} >
            <span className={styles.hiw_itm_img_box}>
              <img src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/hiw_switch_new.svg`}  />
            </span>
            <span className={styles.hiw_itm_txt_box}>
              <p className={styles.text_title}>
                SWITCH AS OFTEN <br className="d-none d-md-block" /> AS YOU WANT
              </p>
              <p className={styles.text_para}> Send your items back by dropping them off at FedEx. Once we get your items back, you instantly get to pick new ones. </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItsWork;
